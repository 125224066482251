import React, {useState, useEffect, useRef} from 'react'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import clsx from 'clsx'
import {default as Apiservices} from '../../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useLocation} from 'react-router-dom'
import EmployeePop from '../../../modules/apps/masters/modal/EmployeePop'
import DatePickerIcon from '../../../modules/apps/masters/commonicons/DatePickerIcon'
import {KTCardBody} from '../../../../_metronic/helpers'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import {UsersListPagination} from '../../../modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import {Container, Row, Col} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'

export default function ViewProjectPop(props: any) {
  const methods = useForm()
  const values = [true]
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [show, setShow] = useState(true)
  const [inputValue, setInputValue] = useState('')
  const [mainTableData, setMainTableData] = React.useState<any | []>([])
  const [mainTableDataCount, setMainTableDataCount] = React.useState<any | []>([])
  const [flag1, setFlag1] = useState(false)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [country, setCountry] = useState<any | []>([])
  const [loading, setLoading] = useState(true)
  const [flag, setFlag] = useState(false)
  const [countries, setCountries] = useState<any | []>([])
  const [useStateCountryId, setUseStateCountryId] = useState<any | []>([])
  const [selectedItem, setSelectedItem] = useState('')
  const ref: any = useRef<HTMLInputElement>(null)
  const [value1, setValue1] = useState<any>([])
  const [value2, setValue2] = useState<any>([])
  const [endDateData, setEndDateData] = useState(null)
  const [joinDateData, setJoinDateData] = useState(null)
  const [checkAdpData, setCheckAdpData] = useState('')
  const [date, setDate] = useState(new Date())
  const [projectsData, setProjectsData] = useState<any | []>([])
  const [indexState, setindexState] = useState('')
  const [totalState, setTotalState] = useState('')
  const [announcement_open_popup, setAnnouncementOpenPoup] = useState(false)
  const [search, setSearch] = useState('')
  const [employeeId, setEmployeeId] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')
  const [search_open, setSearch_Open] = useState(false)
  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const [selectProjectType, setSelectProjectType] = useState<any>([])
  const [BusinessUnit, setBusinessUnit] = useState<any | []>([])
  const [candidateList1, setCandidateList1] = useState<any | []>([])
  const [perPage, setPerPage] = useState(25)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [AllProjectStatusOptions, setAllProjectStatusOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'In Progress',
      value: 'IN_PROGRESS',
    },
    {
      label: 'Initiated',
      value: 'INITIATED',
    },
    {
      label: 'Completed',
      value: 'COMPLETED',
    },
    {
      label: 'Cancelled',
      value: 'CANCELLED',
    },
    {
      label: 'Rejected',
      value: 'REJECTED',
    },
  ])
  const [AllProjectTypeOptions, setAllProjectTypeOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'Placement',
      value: '1',
    },
    {
      label: 'Bench',
      value: '2',
    },
    {
      label: 'Internal',
      value: '3',
    },
  ])
  const [selectProjectStatus, setSelectProjectStatus] = useState<any>([])
  const [select_consultant, setSelectconsultant] = useState<any | []>([])
  const [order5, setOrder5] = useState('ASC')
  const [order6, setOrder6] = useState('ASC')
  const [order7, setOrder7] = useState('ASC')
  const [order8, setOrder8] = useState('ASC')
  const [order9, setOrder9] = useState('ASC')
  const [order10, setOrder10] = useState('ASC')
  const [order11, setOrder11] = useState('ASC')
  const [order12, setOrder12] = useState('ASC')
  const [sort, setSort] = useState<any | []>(false)
  const typeaheadRef4: any = useRef<HTMLInputElement>(null)
  const typeaheadRef5: any = useRef<HTMLInputElement>(null)
  const typeaheadRef6: any = useRef<HTMLInputElement>(null)
  const typeaheadRef7: any = useRef<HTMLInputElement>(null)

  function handleShow(breakpoint: any) {
    setFullscreen(true)
    setShow(true)
  }
  const handleClose = () => {
    setFullscreen(false)
    setShow(false)
    props.cancel(false)
    props.view_open(false)
    props.setViewOpen(false)
  }

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required').nullable(),
  })

  type UserSubmitForm = {
    joining_date: Date
    work_country: string
    profileIdResponse: any
    leaving_date: string
    timesheet_approver: string
    searchcandidate: string
    qb_employee_name: string
    work_city: string
    qb_employee_id: number
    prefix: string
    record_type_status: string
    first_name: string
    name: string
    phone_number: string
    employee_type1: any
    employee_type2: any
    phone: string
    maxLength: any
    // adp_associate_id: number
    last_name: string
    user_status: string
    employeeId: any
    toolName: any
    orgId: any
    domain: string
    roleName: any
    org_name: any
    userStatus: any
    ecdb_user_id: number
    role: string
    email: string
    firstName: string
    full_name: string
    employee_type: string
    // business_unit_id: any
    // user_status: string
    adp_associate_id: string
    status: string
    userId: any
    filteredMatch: any
    id: any
    employee_id: any
    candidateId: any
    org_id: any
    profileResponse: any
    work_state: string
    zipcode: string
    permanent_address: string
    work_location: string
    work_street_address: string
    work_address: string
    project_name: string
    project_type: string
  }
  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema),
  })

  const SuccessToast = (message: any) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 2000,
    })
  }

  const handleUserInput = (e: any) => {
    setInputValue(e.target.value)
  }

  const handleEndDate = async (date: any) => {
    // setDateValue(date)
    setEndDateData(date)
  }

  const handleJoiningDate = async (date: any) => {
    // setDateValue(date)
    setJoinDateData(date)
  }
  const onSubmit = async (data: UserSubmitForm) => {
    setShowBackDrop(true)
    const employeeValid: any = await Apiservices.employeeId()
    let employeeIdData = employeeValid.data.data.employee_id
    setShowBackDrop(false)
  }

  const onChangeDate = (e: any) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD')
    setValue1(newDate)
    props.setFilterDepartment(newDate)
  }
  const onChangeDate1 = (e: any) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD')
    setValue2(newDate)
    props.setFilterStatus(newDate)
  }
  useEffect(() => {
    get_business_units_list()
  }, [])
  const get_business_units_list = async () => {
    // const res: any = await Apiservices.get_business_units_list()
    // if (res && res.status == 200) {
    //   if (res.data.data.length > 0) {
    //     // setBusinessUnitsList(res.data.data)
    //     let obj = {name: 'Select All', value: 'All'}
    //     setBusinessUnitsList([obj, ...res.data.data])
    //   }
    // }
    let y = JSON.parse(localStorage.getItem('bu_list') || '[]')

    let n = y.filter(
      (element: any) =>
        element.roleName?.toLowerCase() == 'accounts' ||
        element.roleName?.toLowerCase() == 'approver' ||
        element.roleName?.toLowerCase() == 'timesheetadmin' ||
        element.roleName?.toLowerCase() == 'hr'
    )
    let business: any = localStorage.getItem('role_Name')
    let numArray: any = business?.split(',')
    let uniqueArr: any = []

    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      if (res.data.data.length > 0) {
        let obj = {name: 'Select All', value: 'All'}
        if (numArray?.includes('admin')) {
          let x = res.data.data.filter((test: any) => uniqueArr.push(test))
          setBusinessUnitsList([obj, ...uniqueArr])
        } else {
          for (let each of res.data.data) {
            for (let item of n) {
              if (each.business_unit_id == item.buId) {
                uniqueArr.push(each)
              }
            }
          }
          setBusinessUnitsList([obj, ...uniqueArr])
        }
      }
    }
  }

  const user = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''

      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })

      return str
    }
  }
  const checkADP = async (e: any) => {
    setCheckAdpData(e.target.value)
  }
  const handleCountries = async (country: any) => {
    if (country && country.length >= 2) {
      const response: any = await Apiservices.getAllEmployesData({country_name: country})
      if (response.data.data !== null) {
        setCountry(response && response.data && response.data.data ? response?.data?.data : '')
      }
    }
  }
  const selectedCountries = async (state: any) => {
    setFlag1(false)
    // setCountries(state)
    setUseStateCountryId(state[0]?.id)
  }
  const handleFilterConsultant1 = async (consult_Name: any) => {
    if (consult_Name && consult_Name.length >= 2) {
      const response: any = await Apiservices.getAllEmployesData({country_name: consult_Name})
      setCandidateList1(response?.data?.data)
    }
  }
  const selectedConsultant1 = async (consult_name: any) => {
    setFlag1(false)
    setSelectconsultant(consult_name)
  }
  const handlePageClick = (event: any) => {
    let page = event.selected
    let selected = page + 1
    setPage(selected)
    // getallprojects(mainTableData, selected, perPage)
    handleClick(selected, perPage)
  }

  const onChange = async (e: any) => {
    const {name, value} = e.target
    if (name == 'perPage') {
      setPerPage(value)
      setPage(1)
      // getallprojects(mainTableData, 1, value)
      handleClick(1, value)
    }
  }

  // const handleClick = async (e: any) => {
  const handleClick = async (page: any, perPage: any) => {
    setSearch_Open(false)
    setShowBackDrop(true)
    let jOinedDate = moment(endDateData, 'YYYY/MM/DD').format('YYYY-MM-DD')
    let joiningDate = moment(joinDateData, 'YYYY/MM/DD').format('YYYY-MM-DD')
    setFlag(true)
    let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')

    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let project_status1 = selectProjectStatus.map((temp: any) => {
      return temp.value
    })
    let project_status2 = project_status1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    if (flag1) {
      const data: any = await Apiservices.projectreportViewData({
        // start_date: startDate,
        start_date: moment(startDate).format('YYYY-MM-DD'),

        // end_date: endDate,
        end_date: moment(endDate).format('YYYY-MM-DD'),

        project_start_date: jOinedDate == 'Invalid date' ? null : jOinedDate,
        joining_date: joiningDate == 'Invalid date' ? null : joiningDate,
        // project_id: useStateCountryId,
        is_placement_project: project_type2 ? project_type2 : null,
        // user_id: useStateCountryId,
        client_name: checkAdpData ? checkAdpData : null,
        project_status: project_status2 ? project_status2 : null,
        business_unit_id: business2 ? business2 : null,
        // employee_id: inputValue
        pagenumber: page,
        pagesize: perPage,
      })
      setMainTableData(data?.data?.data)
      setMainTableDataCount(data?.data?.count)
      setPages(Math.ceil(data?.data?.count / Number(perPage)))
    } else {
      const data: any = await Apiservices.projectreportViewData({
        // start_date: startDate,
        start_date: moment(startDate).format('YYYY-MM-DD'),

        // end_date: endDate,
        end_date: moment(endDate).format('YYYY-MM-DD'),
        business_unit_id: business2 ? business2 : null,
        project_start_date: jOinedDate == 'Invalid date' ? null : jOinedDate,
        joining_date: joiningDate == 'Invalid date' ? null : joiningDate,
        user_id: employee1 ? employee1 : null,
        client_name: checkAdpData ? checkAdpData : null,
        is_placement_project: project_type2 ? project_type2 : null,
        project_status: project_status2 ? project_status2 : null,
        // employee_id: inputValue
        pagenumber: page,
        pagesize: perPage,
      })
      setMainTableData(data?.data?.data)
      setMainTableDataCount(data?.data?.count)
      setPages(Math.ceil(data?.data?.count / Number(perPage)))
    }
    setShowBackDrop(false)
    // const data: any = await Apiservices.projectreportViewData({
    //     // pagenumber: currentPage,
    //     // pagesize: itemsPerPage,
    //     start_date: startDate,
    //     end_date: endDate,
    //     project_id: useStateCountryId,
    //     placement_type: selectedItem,
    //     employee_id: inputValue
    //     // employee_id: "sdjkhdhsgf"
    // })
    // setMainTableData(data?.data?.data)
  }

  const handleChange = (e: any) => {
    setSelectedItem(e.target.value)
  }

  const userName = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''
      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })
      return str
    }
  }
  const handleDescription = async (description: any, total: any, index: any) => {
    var today = moment(date)
    var startOfWeek = today.clone().startOf('isoWeek').format('YYYY-MM-DD')
    var endOfWeek = today.clone().endOf('isoWeek').format('YYYY-MM-DD')
    const res: any = await Apiservices.employeeWiseProject({
      start_date: startOfWeek,
      end_date: endOfWeek,
      user_id: description,
    })
    setProjectsData(res.data.data)
    setAnnouncementOpenPoup(true)
    setindexState(description)
    setTotalState(total)
  }
  const AnnouncePop = (value: any) => {
    setAnnouncementOpenPoup(false)
  }

  const resetData = async (e: any) => {
    setFlag1(true)
    setFlag(false)
    setDateRange([null])
    setSelectedItem('')
    setEmployeeId('')
    setInputValue('')
    setSelectconsultant([])
    setValue('employee_type1', '')
    setValue('employee_type2', '')
    // setValue('business_unit_id', '')
    setUseStateCountryId([])
    setSelectedStatus('')

    setBusinessUnit([])
    setSelectProjectType([])
    setSelectProjectStatus([])

    let dataset = {
      employee_type: '',
    }
    setEndDateData(null)
    setJoinDateData(null)
    reset(dataset)
    setCheckAdpData('')
    setValue('adp_associate_id', '')
    setValue('work_city', '')
    setEndDateData(null)

    // ref.current.clear()

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let projectTypeReset = AllProjectTypeOptions.find((item: any) => item.value == 'All')
    if (!projectTypeReset) {
      AllProjectTypeOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
      // setAllProjectTypeOptions(AllProjectTypeOptions)
    }
    // AllProjectTypeOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let projectTypeReset1 = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
    if (projectTypeReset1) {
      AllProjectTypeOptions.splice(
        AllProjectTypeOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let projectStatusReset = AllProjectStatusOptions.find((item: any) => item.value == 'All')
    if (!projectStatusReset) {
      AllProjectStatusOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllProjectStatusOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let projectStatusReset1 = AllProjectStatusOptions.find((item: any) => item.value == 'empty')
    if (projectStatusReset1) {
      AllProjectStatusOptions.splice(
        AllProjectStatusOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    // const data: any = await Apiservices.employeereportViewData({
    //   // pagenumber: currentPage,
    //   // pagesize: itemsPerPage,
    //   start_date: dates,
    //   end_date: dates1,
    // })
    //     setMainTableData(data?.data?.data)
  }

  const handleChange1 = (e: any) => {
    setSelectedStatus(e.target.value)
  }
  const searchHandleChange = async (value: any) => {
    setSearch_Open(true)
    setSearch(value)

    setSelectedItem('') //project type
    setValue('employee_type1', '') //start date
    setValue('employee_type2', '') //joining date
    // setValue('business_unit_id', '') //business
    setSelectedStatus('') //project status
    setSelectconsultant([])
    setBusinessUnit([])
    setSelectProjectType([])
    setSelectProjectStatus([])
    let dataset = {
      employee_type: '',
    }
    setCheckAdpData('') //client
    setUseStateCountryId([]) //employee
    setEndDateData(null) //start date
    setJoinDateData(null) //joining date
    reset(dataset) //project type
    setValue('adp_associate_id', '') //client
    // ref.current.clear()

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })

    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let projectTypeReset = AllProjectTypeOptions.find((item: any) => item.value == 'All')
    if (!projectTypeReset) {
      AllProjectTypeOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
      // setAllProjectTypeOptions(AllProjectTypeOptions)
    }
    // AllProjectTypeOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })

    let projectTypeReset1 = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
    if (projectTypeReset1) {
      AllProjectTypeOptions.splice(
        AllProjectTypeOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let projectStatusReset = AllProjectStatusOptions.find((item: any) => item.value == 'All')
    if (!projectStatusReset) {
      AllProjectStatusOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllProjectStatusOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let projectStatusReset1 = AllProjectStatusOptions.find((item: any) => item.value == 'empty')
    if (projectStatusReset1) {
      AllProjectStatusOptions.splice(
        AllProjectStatusOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    const data: any = await Apiservices.projectreportViewData({
      // start_date: startDate,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      // end_date: endDate,
      end_date: moment(endDate).format('YYYY-MM-DD'),
      keyword: value,
      pagenumber: page,
      pagesize: perPage,
    })
    setMainTableData(data?.data?.data)
    setMainTableDataCount(data?.data?.count)
    setPages(Math.ceil(data?.data?.count / Number(perPage)))
  }
  // const exporthandleClick = async (e: any) => {
  //   setShowBackDrop(true)
  //   let jOinedDate = moment(endDateData, 'YYYY/MM/DD').format('YYYY-MM-DD')
  //   var today = moment(date)
  //   var startOfWeek = today.clone().startOf('isoWeek').format('YYYY-MM-DD')
  //   var endOfWeek = today.clone().endOf('isoWeek').format('YYYY-MM-DD')
  //   // e.preventDefault();
  //   // if (flag3 == true) {
  //   const res: any = await Apiservices.projectexportreportViewData({
  //     // start_date: startDate,
  //     // end_date: endDate,
  //     // joining_date: jOinedDate == "Invalid date" ? null : jOinedDate,
  //     // project_id: useStateCountryId,
  //     // is_placement_project: selectedItem,
  //     // user_id: useStateCountryId,

  //     // employee_id: inputValue == "" ? null : inputValue,
  //     // start_date: startDate,
  //     // end_date: endDate,
  //     start_date: moment(startDate).format('YYYY-MM-DD'),
  //     end_date: moment(endDate).format('YYYY-MM-DD'),

  //     project_start_date: jOinedDate == 'Invalid date' ? null : jOinedDate,
  //     user_id: useStateCountryId ? useStateCountryId : null,
  //     client_name: checkAdpData ? checkAdpData : null,
  //     is_placement_project: selectedItem ? selectedItem : null,
  //     keyword: search ? search : null,
  //     project_status: selectedStatus ? selectedStatus : null,
  //   })
  //   setShowBackDrop(false)

  //   var link = document.createElement('a')
  //   // If you don't know the name or want to use
  //   // the webserver default set name = ''
  //   link.setAttribute('download', 'Downloaded File')
  //   link.href = res.data.data.blobUrl
  //   document.body.appendChild(link)
  //   link.click()
  //   link.remove()
  //   // }
  // }

  const exporthandleClick = async (e: any) => {
    setShowBackDrop(true)
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let project_status1 = selectProjectStatus.map((temp: any) => {
      return temp.value
    })
    let project_status2 = project_status1.filter((temp: any) => `${temp}`).join(',')

    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')

    if (search_open) {
      // e.preventDefault();
      // if (flag3 == true) {
      const res: any = await Apiservices.projectexportreportViewData({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),

        // project_start_date: jOinedDate == 'Invalid date' ? null : jOinedDate,
        // user_id: useStateCountryId ? useStateCountryId : null,
        // client_name: checkAdpData ? checkAdpData : null,
        // is_placement_project: selectedItem ? selectedItem : null,
        keyword: search ? search : null,
        business_unit_id: business2 ? business2 : null,
        // project_status: selectedStatus ? selectedStatus : null,
      })

      var link = document.createElement('a')
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', 'Downloaded File')
      link.href = res.data.data.blobUrl
      document.body.appendChild(link)
      link.click()
      link.remove()
    } else {
      let jOinedDate = moment(endDateData, 'YYYY/MM/DD').format('YYYY-MM-DD')
      let joiningDate = moment(joinDateData, 'YYYY/MM/DD').format('YYYY-MM-DD')

      // e.preventDefault();
      // if (flag3 == true) {
      const res: any = await Apiservices.projectexportreportViewData({
        // joining_date: jOinedDate == "Invalid date" ? null : jOinedDate,
        // project_id: useStateCountryId,
        // employee_id: inputValue == "" ? null : inputValue,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        project_start_date: jOinedDate == 'Invalid date' ? null : jOinedDate,
        joining_date: joiningDate == 'Invalid date' ? null : joiningDate,
        user_id: employee1 ? employee1 : null,
        client_name: checkAdpData ? checkAdpData : null,
        is_placement_project: project_type2 ? project_type2 : null,
        business_unit_id: business2 ? business2 : null,
        // keyword: search ? search : null,
        project_status: project_status2 ? project_status2 : null,
      })

      var link = document.createElement('a')
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', 'Downloaded File')
      link.href = res?.data?.data?.blobUrl
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
    setShowBackDrop(false)
  }
  const handleProjectType = (selected: any) => {
    setSelectProjectType(selected)
    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllProjectTypeOptions.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setSelectProjectType(x)
          AllProjectTypeOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllProjectTypeOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }
        } else if (each.value == 'empty') {
          setSelectProjectType([])
          let cde = AllProjectTypeOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllProjectTypeOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllProjectTypeOptions(AllProjectTypeOptions)
          }
          AllProjectTypeOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectProjectType(selected)
          let abc = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
          let cde = AllProjectTypeOptions.find((item: any) => item.value == 'All')

          if (!abc) {
            AllProjectTypeOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }
          if (!cde) {
            AllProjectTypeOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }

          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllProjectTypeOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllProjectTypeOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectProjectType(selected)
      AllProjectTypeOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let projectTypeReset = AllProjectTypeOptions.find((item: any) => item.value == 'All')
      if (!projectTypeReset) {
        AllProjectTypeOptions.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }
  const handleBusinessUnit = (selected: any) => {
    // setBusinessUnit(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setBusinessUnit(x)
          BusinessUnitsList.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
        } else if (each.value == 'empty') {
          setBusinessUnit([])
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList)
          }
          BusinessUnitsList.pop({
            name: 'Deselect All',
            value: 'empty',
          })
        } else {
          setBusinessUnit(selected)
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )

          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setBusinessUnit(selected)
      BusinessUnitsList.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
      if (!businessReset) {
        BusinessUnitsList.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }
  const handleProjectStatus = (selected: any) => {
    // setSelectProjectStatus(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllProjectStatusOptions.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setSelectProjectStatus(x)
          AllProjectStatusOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllProjectStatusOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllProjectStatusOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectStatusOptions(AllProjectStatusOptions)
          }
        } else if (each.value == 'empty') {
          setSelectProjectStatus([])
          let cde = AllProjectStatusOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllProjectStatusOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllProjectStatusOptions(AllProjectStatusOptions)
          }
          AllProjectStatusOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectProjectStatus(selected)
          let abc = AllProjectStatusOptions.find((item: any) => item.value == 'empty')
          let cde = AllProjectStatusOptions.find((item: any) => item.value == 'All')

          if (!abc) {
            AllProjectStatusOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectStatusOptions(AllProjectStatusOptions)
          }

          if (!cde) {
            AllProjectStatusOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllProjectStatusOptions(AllProjectStatusOptions)
          }

          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllProjectStatusOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllProjectStatusOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectProjectStatus(selected)
      AllProjectStatusOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let projectStatusReset = AllProjectStatusOptions.find((item: any) => item.value == 'All')
      if (!projectStatusReset) {
        AllProjectTypeOptions.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }

  const sorting5 = (col: any) => {
    if (order5 === 'ASC') {
      const sorted = [...mainTableData]?.sort((a, b) =>
        a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1
      )
      setMainTableData(sorted)
      setOrder5('DSC')
      setSort(false)
    }

    if (order5 === 'DSC') {
      const sorted = [...mainTableData]?.sort((a, b) =>
        a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1
      )
      setMainTableData(sorted)
      setOrder5('ASC')
      setSort(true)
    }
  }

  const sorting7 = (col1: any, col2: any) => {
    if (order7 === 'ASC') {
      const sorted = [...mainTableData]?.sort((a: any, b: any) => {
        let val1 = a[col1] ? a[col1] : a[col2]
        let val2 = b[col1] ? b[col1] : b[col2]
        return val1?.toLowerCase() > val2?.toLowerCase() ? 1 : -1
      })
      setMainTableData(sorted)
      setOrder7('DSC')
      setSort(false)
    }

    if (order7 === 'DSC') {
      const sorted = [...mainTableData]?.sort((a: any, b: any) => {
        let val1 = a[col1] ? a[col1] : a[col2]
        let val2 = b[col1] ? b[col1] : b[col2]
        return val1?.toLowerCase() < val2?.toLowerCase() ? 1 : -1
      })
      setMainTableData(sorted)
      setOrder7('ASC')
      setSort(true)
    }
  }

  const sorting8 = (col: any) => {
    if (order8 === 'ASC') {
      const sorted = [...mainTableData]?.sort((a: any, b: any) => {
        let a1 = a[col] == 1 ? 'Placement' : a[col] == 2 ? 'Bench' : a[col] == 3 ? 'Internal' : ''
        let b1 = b[col] == 1 ? 'Placement' : b[col] == 2 ? 'Bench' : b[col] == 3 ? 'Internal' : ''

        return a1?.toLowerCase() > b1?.toLowerCase() ? 1 : -1
      })
      setMainTableData(sorted)
      setOrder8('DSC')
      setSort(false)
    }

    if (order8 === 'DSC') {
      const sorted = [...mainTableData]?.sort((a: any, b: any) => {
        let a1 = a[col] == 1 ? 'Placement' : a[col] == 2 ? 'Bench' : a[col] == 3 ? 'Internal' : ''
        let b1 = b[col] == 1 ? 'Placement' : b[col] == 2 ? 'Bench' : b[col] == 3 ? 'Internal' : ''

        return a1?.toLowerCase() < b1?.toLowerCase() ? 1 : -1
      })
      setMainTableData(sorted)
      setOrder8('ASC')
      setSort(true)
    }
  }

  const sorting6 = (col: any) => {
    if (order6 === 'ASC') {
      const sorted = [...mainTableData]?.sort((a: any, b: any) => {
        // const numA = parseInt(a[col].match(/\d+/), 10) || 0
        // const numB = parseInt(b[col].match(/\d+/), 10) || 0
        // if (numA === numB) {
        //   return a[col].localeCompare(b[col])
        // } else {
        //   return numA - numB
        // }

        // Remove spaces and convert to lowercase for case-insensitive comparison
        const lowerA = a[col]?.replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col]?.replace(/\s+/g, '').toLowerCase()
        return lowerA?.localeCompare(lowerB)
      })
      setMainTableData(sorted)
      setOrder6('DSC')
      setSort(false)
    }

    if (order6 === 'DSC') {
      const sorted = [...mainTableData]?.sort((a: any, b: any) => {
        // const numA = parseInt(a[col].match(/\d+/), 10) || 0
        // const numB = parseInt(b[col].match(/\d+/), 10) || 0
        // if (numA === numB) {
        //   return b[col].localeCompare(a[col])
        // } else {
        //   return numB - numA
        // }
        const lowerA = a[col]?.replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col]?.replace(/\s+/g, '').toLowerCase()
        return lowerB?.localeCompare(lowerA)
      })

      setMainTableData(sorted)
      setOrder6('ASC')
      setSort(true)
    }
  }
  const sorting9 = (col: any) => {
    if (order9 === 'ASC') {
      const sorted = [...mainTableData]?.sort((a, b) => {
        let dateA = new Date(a[col]),
          dateB = new Date(b[col])
        if (dateB > dateA) {
          return -1
        } else {
          return 1
        }
      })

      setMainTableData(sorted)
      setOrder9('DSC')
      setSort(false)
    }

    if (order9 === 'DSC') {
      const sorted = [...mainTableData]?.sort((a, b) => {
        let dateA = new Date(a[col]),
          dateB = new Date(b[col])
        if (dateB < dateA) {
          return -1
        } else {
          return 1
        }
      })

      setMainTableData(sorted)
      setOrder9('ASC')
      setSort(true)
    }
  }

  const sorting10 = (col: any, col1: any, col2: any) => {
    if (order10 === 'ASC') {
      const sorted = [...mainTableData]?.sort((a: any, b: any) => {
        let a1 = a[col] == 1 ? a[col1] : col2
        let b1 = b[col] == 1 ? b[col1] : col2

        return a1?.toLowerCase() > b1?.toLowerCase() ? 1 : -1
      })
      setMainTableData(sorted)
      setOrder10('DSC')
      setSort(false)
    }

    if (order10 === 'DSC') {
      const sorted = [...mainTableData]?.sort((a: any, b: any) => {
        let a1 = a[col] == 1 ? a[col1] : col2
        let b1 = b[col] == 1 ? b[col1] : col2
        return a1?.toLowerCase() < b1?.toLowerCase() ? 1 : -1
      })
      setMainTableData(sorted)
      setOrder10('ASC')
      setSort(true)
    }
  }

  const sorting11 = (col: any, col1: any, col2: any) => {
    if (order11 === 'ASC') {
      const sorted = [...mainTableData]?.sort((a: any, b: any) => {
        let a1 = a[col] ? a[col][col1] && a[col][col1][col2] : null
        let b1 = b[col] ? b[col][col1] && b[col][col1][col2] : null
        // return parseFloat(a1) > parseFloat(b1) ? 1 : -1

        if (a1 === null) {
          return 1
        } else if (b1 === null) {
          return -1
        } else if (a1 === b1) {
          return 0
        }
        return a1?.toLowerCase() < b1?.toLowerCase() ? -1 : 1
      })

      setMainTableData(sorted)
      setOrder11('DSC')
      setSort(false)
    }

    if (order11 === 'DSC') {
      const sorted = [...mainTableData]?.sort((a: any, b: any) => {
        let a1 = a[col] ? a[col][col1] && a[col][col1][col2] : null
        let b1 = b[col] ? b[col][col1] && b[col][col1][col2] : null
        // return parseFloat(a1) < parseFloat(b1) ? 1 : -1

        if (a1 === null) {
          return 1
        } else if (b1 === null) {
          return -1
        } else if (a1 === b1) {
          return 0
        }
        return a1?.toLowerCase() > b1?.toLowerCase() ? -1 : 1
      })
      setMainTableData(sorted)
      setOrder11('ASC')
      setSort(true)
    }
  }

  const sorting12 = (col: any, col1: any, col2: any) => {
    if (order12 === 'ASC') {
      const sorted = [...mainTableData]?.sort((a, b) => {
        let a1 = a[col] == 'CANCELLED' ? a[col1] : a[col2]
        let b1 = b[col] == 'CANCELLED' ? b[col1] : b[col2]

        let dateA = new Date(a1),
          dateB = new Date(b1)
        if (dateB > dateA) {
          return -1
        } else {
          return 1
        }
      })

      setMainTableData(sorted)
      setOrder12('DSC')
      setSort(false)
    }

    if (order12 === 'DSC') {
      const sorted = [...mainTableData]?.sort((a, b) => {
        let a1 = a[col] == 'CANCELLED' ? a[col1] : a[col2]
        let b1 = b[col] == 'CANCELLED' ? b[col1] : b[col2]

        let dateA = new Date(a1),
          dateB = new Date(b1)
        if (dateB < dateA) {
          return -1
        } else {
          return 1
        }
      })

      setMainTableData(sorted)
      setOrder12('ASC')
      setSort(true)
    }
  }
  return (
    <>
      {announcement_open_popup ? (
        <EmployeePop
          close={AnnouncePop}
          indexState={indexState}
          totalState={totalState}
          role_name={'Admin'}
          setProjectsData={setProjectsData}
          projectsData={projectsData}
        />
      ) : (
        ''
      )}
      <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose()}>
        <Modal.Header closeButton className='p-3 bg-light'>
          <Modal.Title>
            Project Report ( Total No.of Projects started between selected date range )
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3 bg-light'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <BackDrop showBackDrop={showBackDrop} />

            {loading ? (
              <>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-2 py-3'>
                    <div className='row gy-3 align-items-center py-2'>
                      <div className='col-xs-12 col-md-3 col-lg-2'>
                        <label className='form-label fw-semibold'>Date Range *</label>
                        <DatePicker
                          placeholderText='Select Date Range'
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          dateFormat='dd-MMM-yyyy'
                          onChange={(update: any) => {
                            setDateRange(update)
                          }}
                          isClearable={true}
                          monthsShown={2}
                          className={clsx('form-control')}
                          required
                        />
                      </div>

                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Project Type </label>
                        {/* <select
                          value={selectedItem}
                          {...register('employee_type')}
                          onChange={handleChange}
                          className={clsx('form-select', errors.user_status ? 'is-invalid' : '')}
                        >
                          <option value=''>Select Type</option>
                          <option value='1'>Placement</option>
                          <option value='2'>Bench Project</option>
                          <option value='3'>Internal</option>
                        </select>

                        <div className='invalid-feedback'>{errors.user_status?.message}</div> */}

                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(selected: any) => {
                            // setSelectProjectType(selected)
                            handleProjectType(selected)
                            // Keep the menu open when making multiple selections.
                            typeaheadRef5.current.toggleMenu()
                          }}
                          options={AllProjectTypeOptions}
                          placeholder='Select Project Type'
                          ref={typeaheadRef5}
                          selected={selectProjectType}
                        />
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Joining Date</label>
                        <DatePickerIcon
                          className='form-control fs-6'
                          // showIcon
                          {...register('employee_type2', {
                            required: true,
                          })}
                          selected={joinDateData}
                          // dateFormat='dd/MM/yyyy'
                          dateFormat='dd-MMM-yyyy'
                          handleDate={(date: any) => {
                            handleJoiningDate(date)
                            setValue('employee_type2', date)
                          }}
                          placeholderText='Select a date'
                        />
                        <div className='invalid-feedback'>{errors.last_name?.message}</div>
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Start Date</label>
                        <DatePickerIcon
                          className='form-control fs-6'
                          // showIcon
                          {...register('employee_type1', {
                            required: true,
                          })}
                          selected={endDateData}
                          // dateFormat='dd/MM/yyyy'
                          dateFormat='dd-MMM-yyyy'
                          handleDate={(date: any) => {
                            handleEndDate(date)
                            setValue('employee_type1', date)
                          }}
                          placeholderText='Select a date'
                        />
                        <div className='invalid-feedback'>{errors.last_name?.message}</div>
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Client</label>
                        <input
                          {...register('adp_associate_id')}
                          type='text'
                          placeholder='Search Client'
                          maxLength={15}
                          // value={adpId}
                          onChange={(e) => checkADP(e)}
                          className={`form-control ${errors.adp_associate_id ? 'is-invalid' : ''}`}
                        />
                        <div className='invalid-feedback'>{errors.last_name?.message}</div>
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Consultant Name</label>
                        {/* <Typeahead
                          id='basic-typeahead-single'
                          {...register('work_country')}
                          onChange={(e) => selectedCountries(e)}
                          onInputChange={(e) => handleCountries(e)}
                          placeholder='Search Employee '
                          options={country}
                          // selected={countries}
                          labelKey={(country: any) => user(country.full_name)}
                          ref={ref}
                        >
                          {({onClear, selected}) => (
                            <div className='rbt-aux'>
                              {!!selected.length && <ClearButton onClick={onClear} />}
                            </div>
                          )}
                        </Typeahead>
                        <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(e) => {
                            selectedConsultant1(e)
                            typeaheadRef7.current.toggleMenu()
                          }}
                          onInputChange={(e) => handleFilterConsultant1(e)}
                          options={candidateList1}
                          ref={typeaheadRef7}
                          placeholder='Search Consultant'
                          selected={select_consultant}
                          labelKey={(candidateList1: any) => user(candidateList1.full_name)}
                        ></Typeahead>
                      </div>

                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Business Unit</label>
                        {/* <select
                          // value={selectedItem}
                          className={clsx('form-select', errors.employee_type ? 'is-invalid' : '')}
                          {...register('business_unit_id')}
                          onChange={(e: any) => setValue('business_unit_id', e.target.value)}
                        >
                          <option value=''>Select Business Unit</option>
                          {BusinessUnitsList &&
                            BusinessUnitsList.length > 0 &&
                            BusinessUnitsList.map((option: any) => {
                              return (
                                <option value={option.business_unit_id} className='text-capitalize'>
                                  {option.name}
                                </option>
                              )
                            })}
                        </select>
                        <div className='invalid-feedback'>{errors.first_name?.message}</div> */}
                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          // disabled={isView}
                          onChange={(e) => {
                            // setBusinessUnit(e)
                            handleBusinessUnit(e)
                            typeaheadRef4.current.toggleMenu()
                          }}
                          options={BusinessUnitsList}
                          ref={typeaheadRef4}
                          placeholder='Select Business Unit'
                          selected={BusinessUnit}
                          labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                          //   inputProps={{required: true}}
                        ></Typeahead>
                      </div>

                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Project Status </label>

                        {/* <select
                          value={selectedStatus}
                          className={clsx('form-select')}
                          {...register('employee_type1')}
                          onChange={handleChange1}
                        >
                          <option value=''>Select Status</option>
                          <option value='IN_PROGRESS'>In Progress</option>
                          <option value='INITIATED'>Initiated</option>
                          <option value='COMPLETED'>Completed</option>
                          <option value='CANCELLED'>Cancelled</option>
                          <option value='REJECTED'>Rejected</option>
                        </select> */}

                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(selected: any) => {
                            // setSelectProjectStatus(selected)
                            handleProjectStatus(selected)
                            // Keep the menu open when making multiple selections.
                            typeaheadRef6.current.toggleMenu()
                          }}
                          options={AllProjectStatusOptions}
                          placeholder='Select Project Status'
                          ref={typeaheadRef6}
                          selected={selectProjectStatus}
                        />
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Search</label>
                        <input
                          type='text'
                          id='form1'
                          className='form-control'
                          // value={search}
                          {...register('work_city')}
                          placeholder='Search'
                          onChange={(e) => searchHandleChange(e.target.value)}
                        />
                        <div className='invalid-feedback'>{errors.last_name?.message}</div>
                      </div>
                      <div className='col-auto pt-sm-0 pt-md-8'>
                        <button
                          className='btn btn-sm btn-primary'
                          // onClick={handleClick}
                          onClick={() => handleClick(page, perPage)}
                        >
                          Run
                        </button>
                      </div>
                      <div className='col-auto pt-sm-0 pt-md-8'>
                        <button
                          type='button'
                          className='btn btn-danger btn-sm cursor-pointer '
                          id='kt_toolbar_primary_button'
                          onClick={resetData}
                        >
                          Reset
                        </button>
                      </div>
                      <div className='col-auto pt-sm-0 pt-md-8'>
                        <button
                          // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                          className='btn btn-light-primary   btn-sm'
                          onClick={exporthandleClick}
                        >
                          Export
                          {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <h1>load</h1>
            )}
          </form>
          {flag == false && (
            <div className='fs-6 text-gray-500 text-transform-unset'>
              Note: Please select a date range and click run to view the report
            </div>
          )}
          {flag == true && (
            <>
              <KTCardBody className='py-1 '>
                <div className='pt-3'>
                  <div className='card card-custom card-flush '>
                    <div className='card-body p-3 py-3'>
                      <div className='table-responsive padding-down reportTab'>
                        <table
                          id='kt_table_users'
                          className='table table-row-bordered table-row-gray-300 gy-4'
                        >
                          <>
                            <thead>
                              <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                <th
                                  onClick={() => sorting6('project_business_unit_name')}
                                  className='  py-4'
                                >
                                  Business Unit{' '}
                                  <span>
                                    {order6 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting7('display_name', 'full_name')}
                                  className='  py-4'
                                >
                                  Consultant Name{' '}
                                  <span>
                                    {order7 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('employee_type')} className='  py-4'>
                                  Consultant Type{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting8('is_placement_project')}
                                  className='  py-4'
                                >
                                  Project Type{' '}
                                  <span>
                                    {order8 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting6('placement_code')} className='  py-4'>
                                  Placement Id{' '}
                                  <span>
                                    {order6 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('project_name')} className='  py-4'>
                                  project_name{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() =>
                                    sorting10(
                                      'is_placement_project',
                                      'end_customer',
                                      localStorage.getItem('org_name')
                                    )
                                  }
                                  className='  py-4'
                                >
                                  End Customer{' '}
                                  <span>
                                    {order10 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting11('project_vendors', 0, 'client_name')}
                                  className='  py-4'
                                >
                                  Client{' '}
                                  <span>
                                    {order11 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting9('joining_date')} className='  py-4'>
                                  Joining Date
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting9('start_date')} className='  py-4'>
                                  Start Date{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() =>
                                    sorting12('project_status', 'cancel_date', 'end_date')
                                  }
                                  className='  py-4'
                                >
                                  End Date
                                  <span>
                                    {order12 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('project_status')} className='  py-4'>
                                  {' '}
                                  Status{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>{' '}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {mainTableData?.length > 0 ? (
                                <>
                                  {mainTableData?.map((each: any, index: any) => (
                                    <tr
                                      className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                      key={index}
                                    >
                                      <td className=''>
                                        {each.project_business_unit_name
                                          ? each.project_business_unit_name
                                          : '-'}
                                      </td>
                                      <td className='text-capitalize'>
                                        {each.display_name
                                          ? each.display_name
                                          : each.full_name
                                          ? each.full_name
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.employee_type ? each.employee_type : '-'}
                                      </td>
                                      <td className=''>
                                        {/* {each.is_placement_project == false ? 'Bench' : 'Placement'} */}
                                        {each.is_placement_project
                                          ? each.is_placement_project == 1
                                            ? 'Placement'
                                            : each.is_placement_project == 2
                                            ? 'Bench'
                                            : each.is_placement_project == 3
                                            ? 'Internal'
                                            : '-'
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.placement_code ? each.placement_code : '-'}
                                      </td>
                                      <td className='' style={{textTransform: 'capitalize'}}>
                                        {each.project_name ? each.project_name : '-'}
                                      </td>
                                      {/* {each.is_placement_project ? ( */}
                                      {each.is_placement_project == 1 ? (
                                        <td className=''>
                                          {each.end_customer ? each.end_customer : '-'}
                                        </td>
                                      ) : (
                                        <td className=''>{localStorage.getItem('org_name')}</td>
                                      )}
                                      <td className=''>
                                        {each.project_vendors &&
                                        each.project_vendors[0] &&
                                        each.project_vendors[0].client_name
                                          ? each.project_vendors[0].client_name
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.joining_date
                                          ? moment(each.joining_date).format('DD-MMM-YYYY')
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.start_date
                                          ? moment(each.start_date).format('DD-MMM-YYYY')
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.project_status
                                          ? each.project_status == 'CANCELLED'
                                            ? each.cancel_date
                                              ? moment(each.cancel_date).format('DD-MMM-YYYY')
                                              : '-'
                                            : each.project_status == 'COMPLETED'
                                            ? each.end_date
                                              ? moment(each.end_date).format('DD-MMM-YYYY')
                                              : '-'
                                            : '-'
                                          : '-'}
                                      </td>
                                      {/* <td className=''>
                                    {each.end_date
                                      ? moment(each.end_date).format('DD/MM/YYYY')
                                      : '-'}
                                  </td> */}
                                      {/* <td className=''>
                                  {each.project_status ? each.project_status : '-'}
                                </td> */}
                                      <td className=''>
                                        {each.project_status
                                          ? each.project_status == 'IN_PROGRESS'
                                            ? 'In Progress'
                                            : each.project_status == 'INITIATED'
                                            ? 'Initiated'
                                            : each.project_status == 'CANCELLED'
                                            ? 'Cancelled'
                                            : each.project_status == 'COMPLETED'
                                            ? 'Completed'
                                            : each.project_status == 'REJECTED'
                                            ? 'Rejected'
                                            : '-'
                                          : '-'}
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              ) : (
                                ''
                              )}
                            </tbody>
                          </>
                        </table>
                        {(mainTableData?.length == null || mainTableData?.length == 0) && (
                          <div className='text-center  fw-bold text-gray-800'>
                            No Records found{' '}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <UsersListPagination />
              </KTCardBody>
              <div className='d-flex flex-stack py-2'>
                {mainTableData?.length > 0 ? (
                  <div>
                    <Container className='p-0'>
                      <Row className='align-items-center'>
                        <Col xs='auto' className='pe-0'>
                          {' '}
                          <label className='form-label fw-semibold'>Rows Per Page</label>
                        </Col>

                        <Col>
                          <select
                            name='perPage'
                            className='form-select'
                            onChange={onChange}
                            value={perPage}
                          >
                            {/* <option value='10'>10</option> */}
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='75'>75</option>
                            <option value={mainTableDataCount}>All</option>
                          </select>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                ) : null}

                <div className='pr-150'>
                  {mainTableData?.length > 0 ? (
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      pageCount={pages}
                      onPageChange={handlePageClick}
                      containerClassName={'pagination justify-content-right'}
                      activeClassName={'active'}
                      breakLabel={'...'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                    />
                  ) : null}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
