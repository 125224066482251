import React, {useState, useRef, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import clsx from 'clsx'
import {default as Apiservices} from '../../../../common/index'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Container, Row, Col} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import EmployeePop from '../../../modules/apps/masters/modal/EmployeePop'
import ButtonCal from '../../../../localMedia/buttonCal.svg'
import {Tooltip} from '../../../modules/apps/timesheetentry/ComponentTooltip'
import {UsersListPagination} from '../../../modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import {KTCardBody} from '../../../../_metronic/helpers'

export default function OrgComplianceReports(props: any) {
  const methods = useForm()
  const values = [true]
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [show, setShow] = useState(true)
  const [Reportsdata, setReports_Data] = useState<any | []>([])
  const [consultant_type, setConsultantType] = useState('')
  const [orgList, setOrgList] = useState<any | []>([])
  const [test, setTest] = useState(null)
  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const [BusinessUnit, setBusinessUnit] = useState<any | []>([])
  const [selectConsultant, setSelectConsultant] = useState([])
  const [order5, setOrder5] = useState('ASC')
  const [order6, setOrder6] = useState('ASC')
  const [order7, setOrder7] = useState('ASC')
  const [order8, setOrder8] = useState('ASC')
  const [sort, setSort] = useState<any | []>(false)
  const [perPage, setPerPage] = useState(25)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [AllOptions, setAllOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    {
      label: 'Internal',
      value: 'Internal',
    },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'India Offshore',
      value: 'India Offshore',
    },
  ])
  const handleClose = () => {
    setFullscreen(false)
    setShow(false)
    props.cancel(false)
    props.view_open(false)
    props.setViewOpen(false)
  }
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required').nullable(),
  })

  type UserSubmitForm = {
    employee_type: string
    org_name: any
    select_Year: any
  }
  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema),
  })
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [loading, setLoading] = useState(true)
  const [flag, setFlag] = useState(false)
  const [ReportData, setReportData] = useState<any | []>([])
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState([])
  const typeaheadRef: any = useRef<HTMLInputElement>(null)
  const typeaheadRef1: any = useRef<HTMLInputElement>(null)
  const [years, setYears] = useState<any>([])
  const [selectYear, setSelectYear] = useState('')
  const [Org, setOrg] = useState<any>([])
  const [date, setDate] = useState('')
  const [OrganizationReportData, setOrganizationReportData] = useState<any | []>([])
  const [OrganizationReportDataCount, setOrganizationReportDataCount] = useState<any | []>([])

  const onSubmit = async (data: UserSubmitForm) => {
    setShowBackDrop(true)
    const employeeValid: any = await Apiservices.employeeId()
    setShowBackDrop(false)
  }
  useEffect(() => {
    get_business_units_list()
  }, [])
  const get_business_units_list = async () => {
    // const res: any = await Apiservices.get_business_units_list()
    // if (res && res.status == 200) {
    //   if (res.data.data.length > 0) {
    //     // setBusinessUnitsList(res.data.data)
    //     let obj = {name: 'Select All', value: 'All'}
    //     setBusinessUnitsList([obj, ...res.data.data])
    //   }
    // }

    let y = JSON.parse(localStorage.getItem('bu_list') || '[]')

    let n = y.filter(
      (element: any) =>
        element.roleName?.toLowerCase() == 'accounts' ||
        element.roleName?.toLowerCase() == 'approver' ||
        element.roleName?.toLowerCase() == 'timesheetadmin'
    )
    let business: any = localStorage.getItem('role_Name')
    let numArray: any = business?.split(',')
    let uniqueArr: any = []

    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      if (res.data.data.length > 0) {
        let obj = {name: 'Select All', value: 'All'}
        if (numArray?.includes('admin')) {
          let x = res.data.data.filter((test: any) => uniqueArr.push(test))
          setBusinessUnitsList([obj, ...uniqueArr])
        } else {
          for (let each of res.data.data) {
            for (let item of n) {
              if (each.business_unit_id == item.buId) {
                uniqueArr.push(each)
              }
            }
          }
          setBusinessUnitsList([obj, ...uniqueArr])
        }
      }
    }
  }
  const options2: any = [
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    {
      label: 'Internal',
      value: 'Internal',
    },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'India Offshore',
      value: 'India Offshore',
    },
  ]
  useEffect(() => {
    OrganizationList()
    let currYr: any = new Date().getFullYear()
    setSelectYear(currYr)
    let pastYr = 2013
    while (currYr >= pastYr) {
      // setYears([...currYr]);
      years.push(currYr)
      currYr -= 1
    }
  }, [])
  const OrganizationList = async () => {
    const data: any = await Apiservices.getOrganizationsList()
    if (data?.data?.isError == false) {
      setOrgList(data.data.data)
    }
  }

  const handlePageClick = (event: any) => {
    let page = event.selected
    let selected = page + 1
    setPage(selected)
    // getallprojects(mainTableData, selected, perPage)
    handleClick(selected, perPage)
  }

  const onChange = async (e: any) => {
    const {name, value} = e.target
    if (name == 'perPage') {
      setPerPage(value)
      setPage(1)
      // getallprojects(mainTableData, 1, value)
      handleClick(1, value)
    }
  }

  // const handleClick = async () => {
  const handleClick = async (page: any, perPage: any) => {
    setShowBackDrop(true)
    setFlag(true)
    let currentDate: any = new Date()
    if (test) {
      setTest(test)
    } else {
      setTest(currentDate)
    }
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    const employeeResponse: any = await Apiservices.employee_wise_yearly_compliance_report({
      org_id: localStorage.getItem('org_id'),
      year: test
        ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
        : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
      employee_type: stat2 ? stat2 : '',
      business_unit_id: business2 ? business2 : '',
      pagenumber: page,
      pagesize: perPage,
    })
    setOrganizationReportDataCount(employeeResponse?.data?.count[0]?.count)
    setPages(Math.ceil(employeeResponse?.data?.count[0]?.count / Number(perPage)))
    let AllMonths = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    let finalData = []
    if (employeeResponse && employeeResponse.data && employeeResponse.data.isError == false) {
      // setOrganizationReportData(employeeResponse.data.total_data)
      // let orgReport = employeeResponse.data.data //with business data
      let test = employeeResponse.data.total_data // without business unit
      let AllData = [test]

      if (employeeResponse && employeeResponse.data && employeeResponse.data.data?.length > 0) {
        for (let each of business1) {
          let x = employeeResponse.data.data.filter((temp: any) => temp.business_unit_id == each)
          AllData.push(x)
        }
      }
      let data2: any = []
      let data3: any = []
      let data4: any = []
      for (let each of AllData) {
        for (let item of each) {
          data2.push(item)
          finalData.push(item)
        }
      }
      for (let each of AllMonths) {
        let x1 = data2?.filter((temp: any) => temp.monthName == each)
        data3.push(x1)
      }
      for (let item of data3) {
        for (let item1 of item) {
          data4.push(item1)
        }
      }
      // setOrganizationReportData(finalData)
      setOrganizationReportData(data4)
    }
    setShowBackDrop(false)
  }

  const ref: any = useRef<HTMLInputElement>(null)

  const resetData = async (e: any) => {
    setFlag(false)
    setConsultantType('')
    setValue('employee_type', '')
    setTest(null)
    setSelectConsultant([])
    setBusinessUnit([])
    typeaheadRef.current.clear()
    typeaheadRef1.current.clear()
    setSelected([])

    let consultantReset = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })

    let consultantReset1 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset1) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })

    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
  }
  const handleOrganization = (org: any) => {
    console.log(org)
    setOrg(org)
  }
  const yearSelect = (year: any) => {
    setSelectYear(year)
    setDate('')
  }
  const exporthandleClick = async (e: any) => {
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    setShowBackDrop(true)
    const res: any = await Apiservices.export_employee_wise_yearly_compliance_report({
      org_id: localStorage.getItem('org_id'),
      // year: moment(test, 'ddd MMM DD YYYY').format('YYYY'),
      year: test
        ? moment(test, 'ddd MMM DD YYYY').format('YYYY')
        : moment(new Date(), 'ddd MMM DD YYYY').format('YYYY'),
      employee_type: stat2 ? stat2 : '',
      business_unit_id: business2 ? business2 : '',
    })
    var link = document.createElement('a')
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', 'Downloaded File')
    link.href = res?.data?.data?.blobUrl
    document.body.appendChild(link)
    link.click()
    link.remove()
    setShowBackDrop(false)
  }
  let FilteredData = ReportData?.data?.filter(
    (each: any) =>
      each?.employee_id?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.employee_type?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.full_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.joining_date?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.leaving_date?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.emp_working_hours?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.total_hours?.toLowerCase()?.includes(search?.toLowerCase()) ||
      each?.compliance_percentage?.toLowerCase()?.includes(search?.toLowerCase())
  )
  const singleDateSelection = async (date: any) => {
    setTest(date)
  }
  const handleConsultantType = (selected: any) => {
    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllOptions.filter((temp: any) => temp.value != 'All' && temp.value != 'empty')
          setSelectConsultant(x)
          AllOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllOptions(AllOptions)
          }
        } else if (each.value == 'empty') {
          setSelectConsultant([])
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllOptions(AllOptions)
          }
          AllOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectConsultant(selected)
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            // setAllOptions(AllOptions)
          }
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllOptions(AllOptions)
          }
          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectConsultant(selected)
      AllOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let consultantReset = AllOptions.find((item: any) => item.value == 'All')
      if (!consultantReset) {
        AllOptions.unshift({
          label: 'Select All',
          value: 'All',
        })
      }
    }
    // setSelectConsultant(selected)
  }

  const handleBusinessUnit = (selected: any) => {
    // setBusinessUnit(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setBusinessUnit(x)
          BusinessUnitsList.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
        } else if (each.value == 'empty') {
          setBusinessUnit([])
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList)
          }
          BusinessUnitsList.pop({
            name: 'Deselect All',
            value: 'empty',
          })
        } else {
          setBusinessUnit(selected)
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )
          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setBusinessUnit(selected)
      BusinessUnitsList.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
      if (!businessReset) {
        BusinessUnitsList.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }

  const sorting5 = (col: any) => {
    if (order5 === 'ASC') {
      const sorted = [...OrganizationReportData]?.sort((a, b) => (a[col] > b[col] ? 1 : -1))

      setOrganizationReportData(sorted)
      setOrder5('DSC')
      setSort(false)
    }

    if (order5 === 'DSC') {
      const sorted = [...OrganizationReportData]?.sort((a, b) => (a[col] < b[col] ? 1 : -1))
      setOrganizationReportData(sorted)
      setOrder5('ASC')
      setSort(true)
    }
  }

  const sorting6 = (col: any) => {
    if (order6 === 'ASC') {
      const sorted = [...OrganizationReportData]?.sort((a: any, b: any) => {
        const lowerA = a[col]?.replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col]?.replace(/\s+/g, '').toLowerCase()
        return lowerA?.localeCompare(lowerB)
      })

      setOrganizationReportData(sorted)
      setOrder6('DSC')
      setSort(false)
    }

    if (order6 === 'DSC') {
      const sorted = [...OrganizationReportData]?.sort((a: any, b: any) => {
        const lowerA = a[col]?.replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col]?.replace(/\s+/g, '').toLowerCase()
        return lowerB?.localeCompare(lowerA)
      })

      setOrganizationReportData(sorted)
      setOrder6('ASC')
      setSort(true)
    }
  }

  const sorting7 = (col: any) => {
    if (order7 === 'ASC') {
      const sorted = [...OrganizationReportData]?.sort((a, b) => a[col] - b[col])

      setOrganizationReportData(sorted)
      setOrder7('DSC')
      setSort(false)
    }

    if (order7 === 'DSC') {
      const sorted = [...OrganizationReportData]?.sort((a, b) => b[col] - a[col])
      setOrganizationReportData(sorted)
      setOrder7('ASC')
      setSort(true)
    }
  }
  const sorting8 = (col: any, col2: any) => {
    if (order8 === 'ASC') {
      const sorted = [...OrganizationReportData]?.sort((a, b) => {
        var months = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ]
        return months.indexOf(a[col]) - months.indexOf(b[col])
      })
      setOrganizationReportData(sorted)
      setOrder8('DSC')
      setSort(false)
    }

    if (order8 === 'DSC') {
      const sorted = [...OrganizationReportData]?.sort((a: any, b: any) => {
        var months = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ]
        return months.indexOf(b[col]) - months.indexOf(a[col])
      })
      setOrganizationReportData(sorted)
      setOrder8('ASC')
      setSort(true)
    }
  }
  return (
    <>
      <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose()}>
        <Modal.Header closeButton className='p-3 bg-light'>
          <Modal.Title>Organization Compliance Report </Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3 bg-light'>
          <div className='d-flex justify-content-between align-items-center'>
            <div> </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <BackDrop showBackDrop={showBackDrop} />
            {loading ? (
              <>
                {' '}
                <div className='card card-custom card-flush '>
                  <div className='card-body p-2 py-3'>
                    <div className='row gy-3 align-items-center py-2'>
                      <div className='col-xs-12 col-md-3 col-lg-3'>
                        <label className='form-label fw-semibold'>
                          Select Year <span className='fs-9'>(YYYY)</span> *
                        </label>
                        {/* <select
                          className={clsx('form-select', errors.select_Year ? 'is-invalid' : '')}
                          {...register('select_Year', {
                            required: true,
                            onChange: (e) => {
                              yearSelect(e.target.value)
                            },
                          })}
                        >
                          <option value=''>Select Year</option>
                          {years &&
                            years.length > 0 &&
                            years.map((value: any) => {
                              return <option value={value}>{value}</option>
                            })}
                        </select> */}
                        <DatePicker
                          selected={test}
                          onChange={(date: any) => singleDateSelection(date)}
                          placeholderText='Select Year'
                          className={clsx('form-control')}
                          dateFormat='yyyy'
                          showYearPicker
                          // required
                        />
                      </div>
                      {/* <div className='col-xs-12 col-md-3 col-lg-3'>
                        <label className='form-label '>Select Organization</label>
                        <select
                          className={clsx('form-select', errors.org_name ? 'is-invalid' : '')}
                          {...register('org_name', {
                            required: true,
                            onChange: (e) => {
                              handleOrganization(e.target.value)
                            },
                          })}
                        >
                          <option value=''>Select Organization </option>
                          {orgList &&
                            orgList.length > 0 &&
                            orgList.map((item: any) => {
                              return <option value={item.org_id}>{item.org_name}</option>
                            })}
                        </select>
                        <div className='invalid-feedback'>{errors.employee_type?.message}</div>
                      </div> */}

                      <div className='col-xs-12 col-md-3 col-lg-3'>
                        <label className='form-label '>Consultant Type</label>
                        {/* <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(selected: any) => {
                            setSelected(selected)
                            // Keep the menu open when making multiple selections.
                            typeaheadRef.current.toggleMenu()
                          }}
                          options={options2}
                          placeholder='Select Consultant Type'
                          ref={typeaheadRef}
                          selected={selected}
                        /> */}

                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          onChange={(selected: any) => {
                            handleConsultantType(selected)
                            // setSelectConsultant(selected)
                            // Keep the menu open when making multiple selections.
                            typeaheadRef.current.toggleMenu()
                          }}
                          options={AllOptions}
                          placeholder='Select Consultant Type'
                          ref={typeaheadRef}
                          selected={selectConsultant}
                        />
                        {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                      </div>
                      <div className='col-xs-12 col-md-3 col-lg'>
                        <label className='form-label '>Business Unit</label>
                        {/* <Typeahead
                          multiple
                          id='keep-menu-open'
                          // disabled={isView}
                          onChange={(e) => {
                            setBusinessUnit(e)
                            typeaheadRef1.current.toggleMenu()
                          }}
                          options={BusinessUnitsList}
                          ref={typeaheadRef1}
                          placeholder='Select Business Unit'
                          selected={BusinessUnit}
                          labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                          //   inputProps={{required: true}}
                        >
                        
                        </Typeahead> */}

                        <Typeahead
                          multiple
                          id='keep-menu-open'
                          // disabled={isView}
                          onChange={(e) => {
                            // setBusinessUnit(e)
                            handleBusinessUnit(e)
                            typeaheadRef1.current.toggleMenu()
                          }}
                          options={BusinessUnitsList}
                          ref={typeaheadRef1}
                          placeholder='Select Business Unit'
                          selected={BusinessUnit}
                          labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                          //   inputProps={{required: true}}
                        ></Typeahead>
                        {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                      </div>
                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          className='btn btn-sm btn-primary'
                          type='submit'
                          // onClick={handleClick}
                          onClick={() => handleClick(page, perPage)}
                        >
                          Run
                        </button>
                      </div>
                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          type='button'
                          className='btn btn-danger btn-sm cursor-pointer '
                          id='kt_toolbar_primary_button'
                          onClick={resetData}
                        >
                          Reset
                        </button>
                      </div>
                      <div className='col-auto pt-md-0 pt-lg-8'>
                        <button
                          // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                          className='btn btn-light-primary   btn-sm'
                          onClick={exporthandleClick}
                        >
                          {' '}
                          Export
                          {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <h1>load</h1>
            )}
          </form>
          {flag == false && (
            <div className='fs-6 text-gray-500 text-transform-unset'>
              Note: Please select a year and click run to view the report
            </div>
          )}
          {flag == true && (
            <>
              <KTCardBody className='py-1 '>
                <div className='pt-3'>
                  <div className='card card-custom card-flush '>
                    <div className='card-body p-3 py-3'>
                      <div className='table-responsive padding-down reportTab'>
                        <table
                          id='kt_table_users'
                          className='table table-row-bordered table-row-gray-300 gy-4'
                        >
                          <>
                            <thead>
                              <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                <th
                                  onClick={() => sorting5(`localStorage.getItem('org_name')`)}
                                  className='  py-4'
                                >
                                  Org{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                {BusinessUnit && BusinessUnit.length > 0 ? (
                                  <th
                                    onClick={() => sorting6('business_unit_name')}
                                    className='  py-4'
                                  >
                                    Business Unit{' '}
                                    <span>
                                      {order6 === 'ASC' ? (
                                        <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                      ) : (
                                        <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                      )}
                                    </span>
                                  </th>
                                ) : (
                                  ''
                                )}
                                <th onClick={() => sorting8('monthName', test)} className='  py-4'>
                                  Month{' '}
                                  <span>
                                    {order8 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting5('activeConsultants')}
                                  className='  py-4'
                                >
                                  Active Consultants{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting5('submittedConsultants')}
                                  className='  py-4'
                                >
                                  Submitted Consultants{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting7('expectedHours')} className='  py-4'>
                                  Expected Hours{' '}
                                  <span>
                                    {order7 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting7('loggedHours')} className='  py-4'>
                                  Total Hours Spent{' '}
                                  <span>
                                    {order7 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting7('compliance_percentage')}
                                  className='  py-4'
                                >
                                  Compliance %{' '}
                                  <span>
                                    {order7 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                  <Tooltip text={'(Logged Hours/Expected Hours)*100'}>
                                    <span>
                                      <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                                    </span>
                                  </Tooltip>
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {OrganizationReportData?.length > 0 ? (
                                <>
                                  {OrganizationReportData?.map((each: any, index: any) => (
                                    <tr
                                      className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                      key={index}
                                    >
                                      {/* <td className=''>
                                      {each.org_name ? each.org_name : each.business_unit_name}
                                    </td> */}

                                      <td>
                                        {!each.business_unit_name
                                          ? localStorage.getItem('org_name')
                                          : ''}
                                      </td>
                                      {BusinessUnit && BusinessUnit.length > 0 && (
                                        <td>
                                          {each.business_unit_name ? each.business_unit_name : ''}
                                        </td>
                                      )}

                                      <td className=''>
                                        {each.monthName
                                          ? moment(each.monthName, 'MMMM').format('MMM')
                                          : '-'}{' '}
                                        {moment(test, 'ddd MMM DD YYYY').format('YYYY')}
                                      </td>
                                      <td className=''>{each.activeConsultants}</td>
                                      <td className=''>{each.submittedConsultants}</td>
                                      {/* <td className=''>
                                      {each.expectedHours
                                        ? each.expectedHours <= 9
                                          ? '0' + each.expectedHours
                                          : each.expectedHours
                                        : '00'}
                                      :{'00'}
                                    </td> */}
                                      <td className=''>
                                        {each.expectedHours
                                          ? each.expectedHours.toFixed(2)
                                          : '0.00'}
                                      </td>
                                      {/* <td className=''>
                                      <span>
                                        {each.loggedHours <= 9
                                          ? '0' + each.loggedHours
                                          : each.loggedHours}
                                        :{'00'}
                                      </span>
                                    </td> */}
                                      <td className=''>
                                        <span>
                                          {each.loggedHours ? each.loggedHours.toFixed(2) : '0.00'}
                                        </span>
                                      </td>
                                      <td className=''>{each.compliance_percentage}</td>
                                    </tr>
                                  ))}
                                </>
                              ) : (
                                ''
                              )}
                            </tbody>
                          </>
                        </table>
                        {(OrganizationReportData?.length == null ||
                          OrganizationReportData?.length == 0) && (
                          <div className='text-center  fw-bold text-gray-800'>
                            No Records found{' '}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <UsersListPagination />
              </KTCardBody>
              <div className='d-flex flex-stack py-2'>
                {OrganizationReportData?.length > 0 ? (
                  <div>
                    <Container className='p-0'>
                      <Row className='align-items-center'>
                        <Col xs='auto' className='pe-0'>
                          {' '}
                          <label className='form-label fw-semibold'>Rows Per Page</label>
                        </Col>

                        <Col>
                          <select
                            name='perPage'
                            className='form-select'
                            onChange={onChange}
                            value={perPage}
                          >
                            {/* <option value='10'>10</option> */}
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='75'>75</option>
                            <option value={OrganizationReportDataCount}>All</option>
                          </select>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                ) : null}

                <div className='pr-150'>
                  {OrganizationReportData?.length > 0 ? (
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      pageCount={pages}
                      onPageChange={handlePageClick}
                      containerClassName={'pagination justify-content-right'}
                      activeClassName={'active'}
                      breakLabel={'...'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                    />
                  ) : null}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
